import React, { useEffect, useState } from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import classes from './ColumnSection.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography'
import Markdown from 'markdown-to-jsx';
import { fetchSystemCountries } from '../../../../../settings/cms-data';
import { useSelector } from 'react-redux';
import Flag from '../../../../../remitbee/components/flag/Flag';
import theme from '../../../../../remitbee/theme/Theme';
import { snackbar } from '../../../../../remitbee/components/snackbar/SnackBar';
import { useMediaQuery } from '@mui/material';
import Button from '../../../../../remitbee/components/button/Button';

console.log(process.env.REACT_APP_API)

const STORYBOOK_MOCK_DATA = [
        {
            "rate_id": 102,
            "country_id": 2,
            "country_to": "Aland Islands",
            "iso2": "AX",
            "iso3": "ALA",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AX.svg"
        },
        {
            "rate_id": 57,
            "country_id": 6,
            "country_to": "Andorra",
            "iso2": "AD",
            "iso3": "AND",
            "currency_name": "Andorra Euro",
            "currency_code": "EUR",
            "rate": "0.6768",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": "0.8768",
            "special_rate_adjustment": "0.2",
            "special_rate_transfer_amount_limit": "500.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AD.svg"
        },
        {
            "rate_id": 23,
            "country_id": 11,
            "country_to": "Argentina",
            "iso2": "AR",
            "iso3": "ARG",
            "currency_name": "Argentine Peso",
            "currency_code": "ARS",
            "rate": "180.00",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AR.svg"
        },
        {
            "rate_id": 10,
            "country_id": 14,
            "country_to": "Australia",
            "iso2": "AU",
            "iso3": "AUS",
            "currency_name": "Australian Dollar",
            "currency_code": "AUD",
            "rate": "0.9922",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AU.svg"
        },
        {
            "rate_id": 10,
            "country_id": 14,
            "country_to": "Australia",
            "iso2": "AU",
            "iso3": "AUS",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AU.svg"
        },
        {
            "rate_id": 59,
            "country_id": 15,
            "country_to": "Austria",
            "iso2": "AT",
            "iso3": "AUT",
            "currency_name": "Austrian Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AT.svg"
        },
        {
            "rate_id": 81,
            "country_id": 19,
            "country_to": "Bangladesh",
            "iso2": "BD",
            "iso3": "BGD",
            "currency_name": "Bangladeshi Taka",
            "currency_code": "BDT",
            "rate": "82.4332",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": "86.4332",
            "special_rate_adjustment": "4",
            "special_rate_transfer_amount_limit": "500.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BD.svg"
        },
        {
            "rate_id": 58,
            "country_id": 22,
            "country_to": "Belgium",
            "iso2": "BE",
            "iso3": "BEL",
            "currency_name": "Belgian Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BE.svg"
        },
        {
            "rate_id": 80,
            "country_id": 24,
            "country_to": "Benin",
            "iso2": "BJ",
            "iso3": "BEN",
            "currency_name": "Benin CFA Franc",
            "currency_code": "XOF",
            "rate": "447.2111",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BJ.svg"
        },
        {
            "rate_id": 24,
            "country_id": 27,
            "country_to": "Bolivia",
            "iso2": "BO",
            "iso3": "BOL",
            "currency_name": "Boliviano",
            "currency_code": "BOB",
            "rate": "5.1299",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BO.svg"
        },
        {
            "rate_id": 24,
            "country_id": 27,
            "country_to": "Bolivia",
            "iso2": "BO",
            "iso3": "BOL",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BO.svg"
        },
        {
            "rate_id": 135,
            "country_id": 32,
            "country_to": "Brazil",
            "iso2": "BR",
            "iso3": "BRA",
            "currency_name": "Brazilian Real",
            "currency_code": "BRL",
            "rate": "3.6593",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": "4.1593",
            "special_rate_adjustment": "0.5",
            "special_rate_transfer_amount_limit": "500.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BR.svg"
        },
        {
            "rate_id": 113,
            "country_id": 35,
            "country_to": "Bulgaria",
            "iso2": "BG",
            "iso3": "BGR",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BG.svg"
        },
        {
            "rate_id": 129,
            "country_id": 36,
            "country_to": "Burkina Faso",
            "iso2": "BF",
            "iso3": "BFA",
            "currency_name": "Burkina Faso CFA Franc",
            "currency_code": "XOF",
            "rate": "447.2111",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BF.svg"
        },
        {
            "rate_id": 85,
            "country_id": 38,
            "country_to": "Cambodia",
            "iso2": "KH",
            "iso3": "KHM",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/KH.svg"
        },
        {
            "rate_id": 131,
            "country_id": 39,
            "country_to": "Cameroon",
            "iso2": "CM",
            "iso3": "CMR",
            "currency_name": "Cameroon CFA franc",
            "currency_code": "XAF",
            "rate": "447.2111",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CM.svg"
        },
        {
            "rate_id": 96,
            "country_id": 43,
            "country_to": "Central African Republic",
            "iso2": "CF",
            "iso3": "CAF",
            "currency_name": "CFA Franc BEAC",
            "currency_code": "XAF",
            "rate": "447.2111",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CF.svg"
        },
        {
            "rate_id": 25,
            "country_id": 45,
            "country_to": "Chile",
            "iso2": "CL",
            "iso3": "CHL",
            "currency_name": "Chilean Peso",
            "currency_code": "CLP",
            "rate": "678.4847",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CL.svg"
        },
        {
            "rate_id": 25,
            "country_id": 45,
            "country_to": "Chile",
            "iso2": "CL",
            "iso3": "CHL",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CL.svg"
        },
        {
            "rate_id": 16,
            "country_id": 46,
            "country_to": "China",
            "iso2": "CN",
            "iso3": "CHN",
            "currency_name": "Yuan Renminbi",
            "currency_code": "CNY",
            "rate": "5.2262",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CN.svg"
        },
        {
            "rate_id": 16,
            "country_id": 46,
            "country_to": "China",
            "iso2": "CN",
            "iso3": "CHN",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CN.svg"
        },
        {
            "rate_id": 27,
            "country_id": 49,
            "country_to": "Colombia",
            "iso2": "CO",
            "iso3": "COL",
            "currency_name": "Colombian Peso",
            "currency_code": "COP",
            "rate": "2908.3078",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CO.svg"
        },
        {
            "rate_id": 74,
            "country_id": 51,
            "country_to": "Congo",
            "iso2": "CG",
            "iso3": "COG",
            "currency_name": "CFA Franc BEAC",
            "currency_code": "XAF",
            "rate": "447.2111",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CG.svg"
        },
        {
            "rate_id": 28,
            "country_id": 54,
            "country_to": "Costa Rica",
            "iso2": "CR",
            "iso3": "CRI",
            "currency_name": "Costa Rican Colon",
            "currency_code": "CRC",
            "rate": "384.4472",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CR.svg"
        },
        {
            "rate_id": 28,
            "country_id": 54,
            "country_to": "Costa Rica",
            "iso2": "CR",
            "iso3": "CRI",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CR.svg"
        },
        {
            "rate_id": 133,
            "country_id": 60,
            "country_to": "Cote d Ivoire",
            "iso2": "CI",
            "iso3": "CIV",
            "currency_name": "Cote d Ivoire CFA Franc",
            "currency_code": "XOF",
            "rate": "447.2111",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CI.svg"
        },
        {
            "rate_id": 114,
            "country_id": 55,
            "country_to": "Croatia",
            "iso2": "HR",
            "iso3": "HRV",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/HR.svg"
        },
        {
            "rate_id": 60,
            "country_id": 58,
            "country_to": "Cyprus",
            "iso2": "CY",
            "iso3": "CYP",
            "currency_name": "Cypriot Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CY.svg"
        },
        {
            "rate_id": 44,
            "country_id": 59,
            "country_to": "Czech Republic",
            "iso2": "CZ",
            "iso3": "CZE",
            "currency_name": "Czech Koruna",
            "currency_code": "CZK",
            "rate": "16.8813",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CZ.svg"
        },
        {
            "rate_id": 44,
            "country_id": 59,
            "country_to": "Czech Republic",
            "iso2": "CZ",
            "iso3": "CZE",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CZ.svg"
        },
        {
            "rate_id": 86,
            "country_id": 52,
            "country_to": "Democratic Republic of the Congo",
            "iso2": "CD",
            "iso3": "COD",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CD.svg"
        },
        {
            "rate_id": 20,
            "country_id": 61,
            "country_to": "Denmark",
            "iso2": "DK",
            "iso3": "DNK",
            "currency_name": "Danish Krone",
            "currency_code": "DKK",
            "rate": "5.0846",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/DK.svg"
        },
        {
            "rate_id": 29,
            "country_id": 64,
            "country_to": "Dominican Republic",
            "iso2": "DO",
            "iso3": "DOM",
            "currency_name": "Dominican Peso",
            "currency_code": "DOP",
            "rate": "40.6598",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/DO.svg"
        },
        {
            "rate_id": 29,
            "country_id": 64,
            "country_to": "Dominican Republic",
            "iso2": "DO",
            "iso3": "DOM",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/DO.svg"
        },
        {
            "rate_id": 30,
            "country_id": 65,
            "country_to": "Ecuador",
            "iso2": "EC",
            "iso3": "ECU",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/EC.svg"
        },
        {
            "rate_id": 89,
            "country_id": 66,
            "country_to": "Egypt",
            "iso2": "EG",
            "iso3": "EGY",
            "currency_name": "Egyptian Pound",
            "currency_code": "EGP",
            "rate": "22.9379",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/EG.svg"
        },
        {
            "rate_id": 31,
            "country_id": 67,
            "country_to": "El Salvador",
            "iso2": "SV",
            "iso3": "SLV",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SV.svg"
        },
        {
            "rate_id": 61,
            "country_id": 70,
            "country_to": "Estonia",
            "iso2": "EE",
            "iso3": "EST",
            "currency_name": "Estonian Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/EE.svg"
        },
        {
            "rate_id": 68,
            "country_id": 71,
            "country_to": "Ethiopia",
            "iso2": "ET",
            "iso3": "ETH",
            "currency_name": "Ethiopian Birr",
            "currency_code": "ETB",
            "rate": "41.8717",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/ET.svg"
        },
        {
            "rate_id": 115,
            "country_id": 73,
            "country_to": "Faroe Islands",
            "iso2": "FO",
            "iso3": "FRO",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/FO.svg"
        },
        {
            "rate_id": 62,
            "country_id": 75,
            "country_to": "Finland",
            "iso2": "FI",
            "iso3": "FIN",
            "currency_name": "Finnish Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/FI.svg"
        },
        {
            "rate_id": 40,
            "country_id": 76,
            "country_to": "France",
            "iso2": "FR",
            "iso3": "FRA",
            "currency_name": "French Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/FR.svg"
        },
        {
            "rate_id": 43,
            "country_id": 83,
            "country_to": "Germany",
            "iso2": "DE",
            "iso3": "DEU",
            "currency_name": "German Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/DE.svg"
        },
        {
            "rate_id": 43,
            "country_id": 83,
            "country_to": "Germany",
            "iso2": "DE",
            "iso3": "DEU",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/DE.svg"
        },
        {
            "rate_id": 78,
            "country_id": 84,
            "country_to": "Ghana",
            "iso2": "GH",
            "iso3": "GHA",
            "currency_name": "Ghana Cedi",
            "currency_code": "GHS",
            "rate": "8.7143",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": "10.0083",
            "special_rate_adjustment": "1.294",
            "special_rate_transfer_amount_limit": "1000.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/GH.svg"
        },
        {
            "rate_id": 116,
            "country_id": 85,
            "country_to": "Gibraltar",
            "iso2": "GI",
            "iso3": "GIB",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/GI.svg"
        },
        {
            "rate_id": 63,
            "country_id": 86,
            "country_to": "Greece",
            "iso2": "GR",
            "iso3": "GRC",
            "currency_name": "Greek Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/GR.svg"
        },
        {
            "rate_id": 117,
            "country_id": 87,
            "country_to": "Greeland",
            "iso2": "GL",
            "iso3": "GRL",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/GL.svg"
        },
        {
            "rate_id": 32,
            "country_id": 91,
            "country_to": "Guatemala",
            "iso2": "GT",
            "iso3": "GTM",
            "currency_name": "Quetzal",
            "currency_code": "GTQ",
            "rate": "5.802",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/GT.svg"
        },
        {
            "rate_id": 32,
            "country_id": 91,
            "country_to": "Guatemala",
            "iso2": "GT",
            "iso3": "GTM",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/GT.svg"
        },
        {
            "rate_id": 134,
            "country_id": 93,
            "country_to": "Guinea",
            "iso2": "GN",
            "iso3": "GIN",
            "currency_name": "Guinea Franc",
            "currency_code": "GNF",
            "rate": "6373.0134",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/GN.svg"
        },
        {
            "rate_id": 111,
            "country_id": 94,
            "country_to": "Guinea Bissau",
            "iso2": "GW",
            "iso3": "GNB",
            "currency_name": "Guinea Bissau CFA Franc",
            "currency_code": "XOF",
            "rate": "447.2111",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/GW.svg"
        },
        {
            "rate_id": 33,
            "country_id": 96,
            "country_to": "Haiti",
            "iso2": "HT",
            "iso3": "HTI",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/HT.svg"
        },
        {
            "rate_id": 34,
            "country_id": 99,
            "country_to": "Honduras",
            "iso2": "HN",
            "iso3": "HND",
            "currency_name": "Lempira",
            "currency_code": "HNL",
            "rate": "18.3337",
            "service_type": null,
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/HN.svg"
        },
        {
            "rate_id": 34,
            "country_id": 99,
            "country_to": "Honduras",
            "iso2": "HN",
            "iso3": "HND",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/HN.svg"
        },
        {
            "rate_id": 51,
            "country_id": 100,
            "country_to": "Hong Kong",
            "iso2": "HK",
            "iso3": "HKG",
            "currency_name": "Hong Kong Dollar",
            "currency_code": "HKD",
            "rate": "5.804",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/HK.svg"
        },
        {
            "rate_id": 51,
            "country_id": 100,
            "country_to": "Hong Kong",
            "iso2": "HK",
            "iso3": "HKG",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/HK.svg"
        },
        {
            "rate_id": 45,
            "country_id": 101,
            "country_to": "Hungary",
            "iso2": "HU",
            "iso3": "HUN",
            "currency_name": "Forint",
            "currency_code": "HUF",
            "rate": "260.3977",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/HU.svg"
        },
        {
            "rate_id": 118,
            "country_id": 102,
            "country_to": "Iceland",
            "iso2": "IS",
            "iso3": "ISL",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/IS.svg"
        },
        {
            "rate_id": 1,
            "country_id": 103,
            "country_to": "India",
            "iso2": "IN",
            "iso3": "IND",
            "currency_name": "Indian Rupee",
            "currency_code": "INR",
            "rate": "67.70",
            "service_type": null,
            "favorite": "1",
            "special_rate": "70.70",
            "special_rate_adjustment": "3",
            "special_rate_transfer_amount_limit": "250.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/IN.svg"
        },
        {
            "rate_id": 1,
            "country_id": 103,
            "country_to": "India",
            "iso2": "IN",
            "iso3": "IND",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "1",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/IN.svg"
        },
        {
            "rate_id": 136,
            "country_id": 104,
            "country_to": "Indonesia",
            "iso2": "ID",
            "iso3": "IDN",
            "currency_name": "Rupiah",
            "currency_code": "IDR",
            "rate": "11579.9689",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": "11629.9689",
            "special_rate_adjustment": "50",
            "special_rate_transfer_amount_limit": "750.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/ID.svg"
        },
        {
            "rate_id": 64,
            "country_id": 107,
            "country_to": "Ireland",
            "iso2": "IE",
            "iso3": "IRL",
            "currency_name": "Irish Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/IE.svg"
        },
        {
            "rate_id": 65,
            "country_id": 110,
            "country_to": "Italy",
            "iso2": "IT",
            "iso3": "ITA",
            "currency_name": "Italian Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/IT.svg"
        },
        {
            "rate_id": 137,
            "country_id": 111,
            "country_to": "Jamaica",
            "iso2": "JM",
            "iso3": "JAM",
            "currency_name": "Jamaican Dollar",
            "currency_code": "JMD",
            "rate": "114.8698",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/JM.svg"
        },
        {
            "rate_id": 137,
            "country_id": 111,
            "country_to": "Jamaica",
            "iso2": "JM",
            "iso3": "JAM",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/JM.svg"
        },
        {
            "rate_id": 18,
            "country_id": 112,
            "country_to": "Japan",
            "iso2": "JP",
            "iso3": "JPN",
            "currency_name": "Yen",
            "currency_code": "JPY",
            "rate": "109.8866",
            "service_type": "PRIORITY",
            "favorite": "",
            "special_rate": "111.0302",
            "special_rate_adjustment": "1.1436",
            "special_rate_transfer_amount_limit": "100.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/JP.svg"
        },
        {
            "rate_id": 97,
            "country_id": 114,
            "country_to": "Jordan",
            "iso2": "JO",
            "iso3": "JOR",
            "currency_name": "Jordanian Dinar",
            "currency_code": "JOD",
            "rate": "0.5263",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/JO.svg"
        },
        {
            "rate_id": 39,
            "country_id": 116,
            "country_to": "Kenya",
            "iso2": "KE",
            "iso3": "KEN",
            "currency_name": "Kenyan Shilling",
            "currency_code": "KES",
            "rate": "127.5568",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/KE.svg"
        },
        {
            "rate_id": 98,
            "country_id": 120,
            "country_to": "Kuwait",
            "iso2": "KW",
            "iso3": "KWT",
            "currency_name": "Kuwaiti Dinar",
            "currency_code": "KWD",
            "rate": "0.2285",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/KW.svg"
        },
        {
            "rate_id": 119,
            "country_id": 123,
            "country_to": "Lativa",
            "iso2": "LV",
            "iso3": "LVA",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/LV.svg"
        },
        {
            "rate_id": 99,
            "country_id": 125,
            "country_to": "Lesotho",
            "iso2": "LS",
            "iso3": "LSO",
            "currency_name": "Lesotho Loti",
            "currency_code": "LSL",
            "rate": "14.0916",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/LS.svg"
        },
        {
            "rate_id": 120,
            "country_id": 128,
            "country_to": "Liechtenstein",
            "iso2": "LI",
            "iso3": "LIE",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/LI.svg"
        },
        {
            "rate_id": 121,
            "country_id": 129,
            "country_to": "Lithuania",
            "iso2": "LT",
            "iso3": "LTU",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/LT.svg"
        },
        {
            "rate_id": 50,
            "country_id": 130,
            "country_to": "Luxembourg",
            "iso2": "LU",
            "iso3": "LUX",
            "currency_name": "Luxembourg Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/LU.svg"
        },
        {
            "rate_id": 100,
            "country_id": 133,
            "country_to": "Madagascar",
            "iso2": "MG",
            "iso3": "MDG",
            "currency_name": "Malagasy Ariary",
            "currency_code": "MGA",
            "rate": "3385.3494",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MG.svg"
        },
        {
            "rate_id": 101,
            "country_id": 134,
            "country_to": "Malawi",
            "iso2": "MW",
            "iso3": "MWI",
            "currency_name": "Kwacha",
            "currency_code": "MWK",
            "rate": "1249.4048",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MW.svg"
        },
        {
            "rate_id": 42,
            "country_id": 135,
            "country_to": "Malaysia",
            "iso2": "MY",
            "iso3": "MYS",
            "currency_name": "Malaysian Ringgit",
            "currency_code": "MYR",
            "rate": "3.502",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MY.svg"
        },
        {
            "rate_id": 42,
            "country_id": 135,
            "country_to": "Malaysia",
            "iso2": "MY",
            "iso3": "MYS",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MY.svg"
        },
        {
            "rate_id": 109,
            "country_id": 137,
            "country_to": "Mali",
            "iso2": "ML",
            "iso3": "MLI",
            "currency_name": "Mali CFA Franc",
            "currency_code": "XOF",
            "rate": "447.2111",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/ML.svg"
        },
        {
            "rate_id": 66,
            "country_id": 138,
            "country_to": "Malta",
            "iso2": "MT",
            "iso3": "MLT",
            "currency_name": "Maltese Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MT.svg"
        },
        {
            "rate_id": 122,
            "country_id": 141,
            "country_to": "Mauritania",
            "iso2": "MR",
            "iso3": "MRT",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MR.svg"
        },
        {
            "rate_id": 90,
            "country_id": 142,
            "country_to": "Mauritius",
            "iso2": "MU",
            "iso3": "MUS",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MU.svg"
        },
        {
            "rate_id": 90,
            "country_id": 142,
            "country_to": "Mauritius",
            "iso2": "MU",
            "iso3": "MUS",
            "currency_name": "Mauritius Rupee",
            "currency_code": "MUR",
            "rate": "32.9794",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MU.svg"
        },
        {
            "rate_id": 26,
            "country_id": 144,
            "country_to": "Mexico",
            "iso2": "MX",
            "iso3": "MEX",
            "currency_name": "Mexican Peso",
            "currency_code": "MXN",
            "rate": "13.7276",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MX.svg"
        },
        {
            "rate_id": 123,
            "country_id": 146,
            "country_to": "Moldova",
            "iso2": "MD",
            "iso3": "MDA",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MD.svg"
        },
        {
            "rate_id": 67,
            "country_id": 147,
            "country_to": "Monaco",
            "iso2": "MC",
            "iso3": "MCO",
            "currency_name": "Monaco Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MC.svg"
        },
        {
            "rate_id": 56,
            "country_id": 149,
            "country_to": "Montenegro",
            "iso2": "ME",
            "iso3": "MNE",
            "currency_name": "Montenegro Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/ME.svg"
        },
        {
            "rate_id": 92,
            "country_id": 151,
            "country_to": "Morocco",
            "iso2": "MA",
            "iso3": "MAR",
            "currency_name": "Moroccan Dirham",
            "currency_code": "MAD",
            "rate": "7.3894",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MA.svg"
        },
        {
            "rate_id": 93,
            "country_id": 152,
            "country_to": "Mozambique",
            "iso2": "MZ",
            "iso3": "MOZ",
            "currency_name": "Mozambique Metical",
            "currency_code": "MZN",
            "rate": "47.386",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/MZ.svg"
        },
        {
            "rate_id": 94,
            "country_id": 154,
            "country_to": "Namibia",
            "iso2": "NA",
            "iso3": "NAM",
            "currency_name": "Namibian Dollar",
            "currency_code": "NAD",
            "rate": "14.0916",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/NA.svg"
        },
        {
            "rate_id": 82,
            "country_id": 156,
            "country_to": "Nepal",
            "iso2": "NP",
            "iso3": "NPL",
            "currency_name": "Nepalese Rupee",
            "currency_code": "NPR",
            "rate": "98.6774",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/NP.svg"
        },
        {
            "rate_id": 48,
            "country_id": 157,
            "country_to": "Netherlands",
            "iso2": "NL",
            "iso3": "NLD",
            "currency_name": "Netherlands Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/NL.svg"
        },
        {
            "rate_id": 49,
            "country_id": 159,
            "country_to": "New Zealand",
            "iso2": "NZ",
            "iso3": "NZL",
            "currency_name": "New Zealand Dollar",
            "currency_code": "NZD",
            "rate": "1.2132",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/NZ.svg"
        },
        {
            "rate_id": 110,
            "country_id": 161,
            "country_to": "Niger",
            "iso2": "NE",
            "iso3": "NER",
            "currency_name": "Niger CFA Franc",
            "currency_code": "XOF",
            "rate": "447.2111",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/NE.svg"
        },
        {
            "rate_id": 79,
            "country_id": 162,
            "country_to": "Nigeria",
            "iso2": "NG",
            "iso3": "NGA",
            "currency_name": "Naira",
            "currency_code": "NGN",
            "rate": "645.6718",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/NG.svg"
        },
        {
            "rate_id": 21,
            "country_id": 166,
            "country_to": "Norway",
            "iso2": "NO",
            "iso3": "NOR",
            "currency_name": "Norwegian Krone",
            "currency_code": "NOK",
            "rate": "7.7684",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/NO.svg"
        },
        {
            "rate_id": 19,
            "country_id": 168,
            "country_to": "Pakistan",
            "iso2": "PK",
            "iso3": "PAK",
            "currency_name": "Pakistan Rupee",
            "currency_code": "PKR",
            "rate": "217.50",
            "service_type": null,
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/PK.svg"
        },
        {
            "rate_id": 35,
            "country_id": 173,
            "country_to": "Paraguay",
            "iso2": "PY",
            "iso3": "PRY",
            "currency_name": "Guarani",
            "currency_code": "PYG",
            "rate": "5413.1953",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/PY.svg"
        },
        {
            "rate_id": 35,
            "country_id": 173,
            "country_to": "Paraguay",
            "iso2": "PY",
            "iso3": "PRY",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/PY.svg"
        },
        {
            "rate_id": 36,
            "country_id": 174,
            "country_to": "Peru",
            "iso2": "PE",
            "iso3": "PER",
            "currency_name": "Nuevo Sol",
            "currency_code": "PEN",
            "rate": "2.7784",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/PE.svg"
        },
        {
            "rate_id": 36,
            "country_id": 174,
            "country_to": "Peru",
            "iso2": "PE",
            "iso3": "PER",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/PE.svg"
        },
        {
            "rate_id": 15,
            "country_id": 175,
            "country_to": "Philippines",
            "iso2": "PH",
            "iso3": "PHL",
            "currency_name": "Philippine Peso",
            "currency_code": "PHP",
            "rate": "51.843",
            "service_type": null,
            "favorite": "0",
            "special_rate": "53.343",
            "special_rate_adjustment": "1.5",
            "special_rate_transfer_amount_limit": "250.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/PH.svg"
        },
        {
            "rate_id": 15,
            "country_id": 175,
            "country_to": "Philippines",
            "iso2": "PH",
            "iso3": "PHL",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/PH.svg"
        },
        {
            "rate_id": 46,
            "country_id": 177,
            "country_to": "Poland",
            "iso2": "PL",
            "iso3": "POL",
            "currency_name": "Zloty",
            "currency_code": "PLN",
            "rate": "2.9827",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/PL.svg"
        },
        {
            "rate_id": 46,
            "country_id": 177,
            "country_to": "Poland",
            "iso2": "PL",
            "iso3": "POL",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/PL.svg"
        },
        {
            "rate_id": 69,
            "country_id": 178,
            "country_to": "Portugal",
            "iso2": "PT",
            "iso3": "PRT",
            "currency_name": "Portuguese Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/PT.svg"
        },
        {
            "rate_id": 38,
            "country_id": 180,
            "country_to": "Qatar",
            "iso2": "QA",
            "iso3": "QAT",
            "currency_name": "Qatari Rial",
            "currency_code": "QAR",
            "rate": "2.7019",
            "service_type": "PRIORITY",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/QA.svg"
        },
        {
            "rate_id": 124,
            "country_id": 181,
            "country_to": "Romania",
            "iso2": "RO",
            "iso3": "ROU",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/RO.svg"
        },
        {
            "rate_id": 87,
            "country_id": 183,
            "country_to": "Rwanda",
            "iso2": "RW",
            "iso3": "RWA",
            "currency_name": "Rwanda Franc",
            "currency_code": "RWF",
            "rate": "943.0891",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/RW.svg"
        },
        {
            "rate_id": 70,
            "country_id": 193,
            "country_to": "San Marino",
            "iso2": "SM",
            "iso3": "SMR",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SM.svg"
        },
        {
            "rate_id": 52,
            "country_id": 195,
            "country_to": "Saudi Arabia",
            "iso2": "SA",
            "iso3": "SAU",
            "currency_name": "Saudi Riyal",
            "currency_code": "SAR",
            "rate": "2.7835",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SA.svg"
        },
        {
            "rate_id": 127,
            "country_id": 196,
            "country_to": "Senegal",
            "iso2": "SN",
            "iso3": "SEN",
            "currency_name": "Senegal CFA Franc",
            "currency_code": "XOF",
            "rate": "447.2111",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SN.svg"
        },
        {
            "rate_id": 125,
            "country_id": 197,
            "country_to": "Serbia",
            "iso2": "RS",
            "iso3": "SRB",
            "currency_name": "Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/RS.svg"
        },
        {
            "rate_id": 12,
            "country_id": 200,
            "country_to": "Singapore",
            "iso2": "SG",
            "iso3": "SGP",
            "currency_name": "Singapore Dollar",
            "currency_code": "SGD",
            "rate": "22.8526",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": "25.8526",
            "special_rate_adjustment": "3",
            "special_rate_transfer_amount_limit": "400.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SG.svg"
        },
        {
            "rate_id": 12,
            "country_id": 200,
            "country_to": "Singapore",
            "iso2": "SG",
            "iso3": "SGP",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SG.svg"
        },
        {
            "rate_id": 71,
            "country_id": 202,
            "country_to": "Slovakia",
            "iso2": "SK",
            "iso3": "SVK",
            "currency_name": "Slovakia Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SK.svg"
        },
        {
            "rate_id": 72,
            "country_id": 203,
            "country_to": "Slovenia",
            "iso2": "SI",
            "iso3": "SVN",
            "currency_name": "Slovenia Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SI.svg"
        },
        {
            "rate_id": 53,
            "country_id": 206,
            "country_to": "South Africa",
            "iso2": "ZA",
            "iso3": "ZAF",
            "currency_name": "Rand",
            "currency_code": "ZAR",
            "rate": "14.0916",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/ZA.svg"
        },
        {
            "rate_id": 76,
            "country_id": 119,
            "country_to": "South Korea",
            "iso2": "KR",
            "iso3": "KOR",
            "currency_name": "South Korean Won",
            "currency_code": "KRW",
            "rate": "989.5695",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/KR.svg"
        },
        {
            "rate_id": 47,
            "country_id": 209,
            "country_to": "Spain",
            "iso2": "ES",
            "iso3": "ESP",
            "currency_name": "Spanish Euro",
            "currency_code": "EUR",
            "rate": "0.6818",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/ES.svg"
        },
        {
            "rate_id": 8,
            "country_id": 210,
            "country_to": "Sri Lanka",
            "iso2": "LK",
            "iso3": "LKA",
            "currency_name": "Sri Lanka Rupee",
            "currency_code": "LKR",
            "rate": "230.00",
            "service_type": "REGULAR",
            "favorite": "1",
            "special_rate": "241.00",
            "special_rate_adjustment": "11",
            "special_rate_transfer_amount_limit": "500.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/LK.svg"
        },
        {
            "rate_id": 8,
            "country_id": 210,
            "country_to": "Sri Lanka",
            "iso2": "LK",
            "iso3": "LKA",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "1",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/LK.svg"
        },
        {
            "rate_id": 22,
            "country_id": 215,
            "country_to": "Sweden",
            "iso2": "SE",
            "iso3": "SWE",
            "currency_name": "Swedish Krona",
            "currency_code": "SEK",
            "rate": "7.7593",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SE.svg"
        },
        {
            "rate_id": 22,
            "country_id": 215,
            "country_to": "Sweden",
            "iso2": "SE",
            "iso3": "SWE",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SE.svg"
        },
        {
            "rate_id": 13,
            "country_id": 216,
            "country_to": "Switzerland",
            "iso2": "CH",
            "iso3": "CHE",
            "currency_name": "Swiss Franc",
            "currency_code": "CHF",
            "rate": "0.6449",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CH.svg"
        },
        {
            "rate_id": 13,
            "country_id": 216,
            "country_to": "Switzerland",
            "iso2": "CH",
            "iso3": "CHE",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CH.svg"
        },
        {
            "rate_id": 88,
            "country_id": 220,
            "country_to": "Tanzania",
            "iso2": "TZ",
            "iso3": "TZA",
            "currency_name": "Tanzanian Shilling",
            "currency_code": "TZS",
            "rate": "1882.4007",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/TZ.svg"
        },
        {
            "rate_id": 54,
            "country_id": 221,
            "country_to": "Thailand",
            "iso2": "TH",
            "iso3": "THA",
            "currency_name": "Baht",
            "currency_code": "THB",
            "rate": "26.3219",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/TH.svg"
        },
        {
            "rate_id": 128,
            "country_id": 223,
            "country_to": "Togo",
            "iso2": "TG",
            "iso3": "TGO",
            "currency_name": "Togo CFA Franc",
            "currency_code": "XOF",
            "rate": "447.2111",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/TG.svg"
        },
        {
            "rate_id": 112,
            "country_id": 227,
            "country_to": "Tunisia",
            "iso2": "TN",
            "iso3": "TUN",
            "currency_name": "Tunisian Dinar",
            "currency_code": "TND",
            "rate": "2.3098",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/TN.svg"
        },
        {
            "rate_id": 55,
            "country_id": 228,
            "country_to": "Turkey",
            "iso2": "TR",
            "iso3": "TUR",
            "currency_name": "Turkish Lira",
            "currency_code": "TRY",
            "rate": "22.4107",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/TR.svg"
        },
        {
            "rate_id": 55,
            "country_id": 228,
            "country_to": "Turkey",
            "iso2": "TR",
            "iso3": "TUR",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/TR.svg"
        },
        {
            "rate_id": 73,
            "country_id": 232,
            "country_to": "Uganda",
            "iso2": "UG",
            "iso3": "UGA",
            "currency_name": "Uganda Shilling",
            "currency_code": "UGX",
            "rate": "2660.6296",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/UG.svg"
        },
        {
            "rate_id": 139,
            "country_id": 233,
            "country_to": "Ukraine",
            "iso2": "UA",
            "iso3": "UKR",
            "currency_name": "Hryvnia",
            "currency_code": "UAH",
            "rate": "27.8938",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/UA.svg"
        },
        {
            "rate_id": 139,
            "country_id": 233,
            "country_to": "Ukraine",
            "iso2": "UA",
            "iso3": "UKR",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/UA.svg"
        },
        {
            "rate_id": 17,
            "country_id": 234,
            "country_to": "United Arab Emirates",
            "iso2": "AE",
            "iso3": "ARE",
            "currency_name": "UAE Dirham",
            "currency_code": "AED",
            "rate": "2.656",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AE.svg"
        },
        {
            "rate_id": 17,
            "country_id": 234,
            "country_to": "United Arab Emirates",
            "iso2": "AE",
            "iso3": "ARE",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "PRIORITY",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AE.svg"
        },
        {
            "rate_id": 11,
            "country_id": 235,
            "country_to": "United Kingdom",
            "iso2": "GB",
            "iso3": "GBR",
            "currency_name": "Pound Sterling",
            "currency_code": "GBP",
            "rate": "0.5753",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/GB.svg"
        },
        {
            "rate_id": 9,
            "country_id": 236,
            "country_to": "United States",
            "iso2": "US",
            "iso3": "USA",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "1",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/US.svg"
        },
        {
            "rate_id": 37,
            "country_id": 238,
            "country_to": "Uruguay",
            "iso2": "UY",
            "iso3": "URY",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/UY.svg"
        },
        {
            "rate_id": 37,
            "country_id": 238,
            "country_to": "Uruguay",
            "iso2": "UY",
            "iso3": "URY",
            "currency_name": "Peso Uruguayo",
            "currency_code": "UYU",
            "rate": "29.1711",
            "service_type": null,
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/UY.svg"
        },
        {
            "rate_id": 7,
            "country_id": 242,
            "country_to": "Vietnam",
            "iso2": "VN",
            "iso3": "VNM",
            "currency_name": "Canadian Dollar",
            "currency_code": "CAD",
            "rate": "1.00",
            "service_type": "REGULAR",
            "favorite": "1",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/VN.svg"
        },
        {
            "rate_id": 7,
            "country_id": 242,
            "country_to": "Vietnam",
            "iso2": "VN",
            "iso3": "VNM",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "1",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/VN.svg"
        },
        {
            "rate_id": 7,
            "country_id": 242,
            "country_to": "Vietnam",
            "iso2": "VN",
            "iso3": "VNM",
            "currency_name": "Dong",
            "currency_code": "VND",
            "rate": "17611.3916",
            "service_type": "REGULAR",
            "favorite": "1",
            "special_rate": "17711.3916",
            "special_rate_adjustment": "100",
            "special_rate_transfer_amount_limit": "500.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/VN.svg"
        },
        {
            "rate_id": 103,
            "country_id": 248,
            "country_to": "Zambia",
            "iso2": "ZM",
            "iso3": "ZMB",
            "currency_name": "Zambian Kwacha",
            "currency_code": "ZMW",
            "rate": "19.6195",
            "service_type": "REGULAR",
            "favorite": "",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/ZM.svg"
        },
        {
            "rate_id": 84,
            "country_id": 249,
            "country_to": "Zimbabwe",
            "iso2": "ZW",
            "iso3": "ZWE",
            "currency_name": "United States Dollar",
            "currency_code": "USD",
            "rate": "0.7322",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/ZW.svg"
        },
        {
            "rate_id": 84,
            "country_id": 249,
            "country_to": "Zimbabwe",
            "iso2": "ZW",
            "iso3": "ZWE",
            "currency_name": "Zimbabwe Dollar",
            "currency_code": "ZWL",
            "rate": "6593.2934",
            "service_type": "REGULAR",
            "favorite": "0",
            "special_rate": null,
            "special_rate_adjustment": null,
            "special_rate_transfer_amount_limit": null,
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/ZW.svg"
        }
    ]

const CustomBoxList = ({ type, list = [], actionsAvailable }) => {
    const [fullList, setFullList] = useState(list);
    const [shortList, setShortList] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const { countries } = useSelector((state:any) => state?.system);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`)

    const fecthCountries = async () => {
        try {
        const systemCountries = !countries?.length ? await fetchSystemCountries() : { rates: countries };
        if (systemCountries) {
          setFullList((systemCountries.rates));
          setShortList(systemCountries.rates.slice(0, isMobile ? 8 : 16));
        }   
        } catch (error) {
            snackbar.error('Error fetching countries', 2000)
            if((process.env.NODE_ENV) as string !== 'prod') {  // Added for storybook testing
                setFullList(STORYBOOK_MOCK_DATA);
                setShortList(STORYBOOK_MOCK_DATA.slice(0, isMobile ? 8 : 16));
            }
        }
      }

    useEffect(() => {
        if(type === 'countryBoxList') fecthCountries();
        else {
            setFullList(list)
            setShortList(list.slice(0, isMobile ? 8 : 16))
        }
    }, [isMobile]);

    const handleClick = () => {
        setShowAll(!showAll);
        setShortList(showAll ? fullList.slice(0, isMobile ? 8 : 16) : fullList)
    }


    return (
        <>
        <Box className={classes['rb-cards-container']}>
            {type !== 'countryBoxList' ? shortList.map((tag: any, index: number) => (
                <Box onClick={tag?.onClick ? () => window.open(tag?.onClick) : null} key={index} className={classes['rb-card-container']} style={{ cursor: tag?.onClick ? 'pointer' : '' }}>
                    <Typography variant={tag?.textVariant} color={tag?.textColor} weight='medium'>
                        <Markdown>{tag?.text}</Markdown>
                    </Typography>
            </Box>))
            :
            shortList.map((tag: any, index: number) => (
                <Box onClick={tag?.onClick ? () => window.open(tag?.onClick) : null} key={index} className={classes['rb-card-container']} style={{ cursor: tag?.onClick ? 'pointer' : '' }}>
                    <Flag width={36} height={24} code={tag?.iso2} />
                    <Typography variant='textMd' color={theme.palette.primary.navy} weight='medium'>
                        {tag?.country_to}
                    </Typography>
                </Box>))
            }
        </Box>
        {!actionsAvailable && fullList.length > 12 && <Box mt={isMobile ? 10 : 12} display='flex' justifyContent='center' alignItems='center'>
          <Button fullWidth={isMobile} className={classes['rb-button-regular']} variant='outlined' onClick={handleClick} id='show-all'>
            {showAll ? 'Show Less' : 'Show All'}
          </Button>
        </Box>}
        </>
    )
}

export default CustomBoxList