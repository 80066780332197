import React from 'react';
import Tabs from '../../../../../remitbee/components/tab/Tab';
import MoneyTransferComponent from './MoneyTransferComponent'
import CurrencyExchangeComponent from './CurrencyExchangeComponent'
import Box from '../../../../../remitbee/components/box/Box';
import classes from './MultiConverter.module.scss';

interface Props {
    componentType?: string;
    moneyTransferProps?: any;
    legacyMoneyTransferProps?: any;
    storybookMock?: any;
    type?: string
}

const MultiConverter: React.FC<Props> = ({ componentType, storybookMock, moneyTransferProps, legacyMoneyTransferProps, type }) => {
    let tabContent = [
        {
            label: 'Money Transfer',
            content: <MoneyTransferComponent storybookMock={storybookMock} />,
        },
        {
            label: 'Currency Exchange',
            content: <CurrencyExchangeComponent type={type} storybookMock={storybookMock} />,
        }
    ];

    switch (componentType) {
        case 'moneyTransfer':
            tabContent = [
                {
                    label: 'Money Transfer',
                    content: <MoneyTransferComponent storybookMock={storybookMock} {...moneyTransferProps} {...legacyMoneyTransferProps} />,
                },
            ];
            break;
        case 'currencyExchange':
            tabContent = [
                {
                    label: 'Currency Exchange',
                    content: <CurrencyExchangeComponent type={type} storybookMock={storybookMock} />,
                },
            ];
            break;
    }

    return (
        type === 'horizontal' ? 
            <CurrencyExchangeComponent type={type} storybookMock={storybookMock} />
            : <Box className={classes['rb-root']} style={{ paddingTop: tabContent.length <= 1 && 0, width: type === 'horizontal' && '100% !important' }}>
            <Tabs hideTableBodySpacing tabContent={tabContent} hideTabHeader={tabContent.length <= 1} dynamicWidth tabHeaderClassName={classes['rb-tabs']} tabLabelClassName={classes['rb-tabs-label']} />
        </Box>
    );
};

export default MultiConverter;