import React from 'react';

const ExchangeIconHorizontal = ({ width = '25', height = '24', color = '#31517A' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="https://www.w3.org/2000/svg">
        <path 
        d="M4.40234 17H20.4023M20.4023 17L16.4023 13M20.4023 17L16.4023 21M20.4023 7H4.40234M4.40234 7L8.40234 3M4.40234 7L8.40234 11" 
        stroke={color}
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    </svg>
  );
};

export default ExchangeIconHorizontal;
