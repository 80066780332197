import { Skeleton, useMediaQuery } from '@mui/material';
import React from 'react'
import theme from '../../../../../remitbee/theme/Theme';
import Box from '../../../../../remitbee/components/box/Box';
import classes from './HorizontalExchangeConverter.module.scss'
import Button from '../../../../../remitbee/components/button/Button';
import CurrencyInputField from '../../../../../remitbee/components/moneyTransferBox/CurrencyInputField';
import ExchangeIconHorizontal from '../../../../../remitbee/icons/ExchangeIconHorizontal';
import Typography from '../../../../../remitbee/components/typography/Typography';

type CEBox = {
    /**
     * `string` - unique character
     */
    id: string;
    /**
     * List of countries
     */
    countriesList: any[];
    /**
     * Primary currency object holds (iso2, currency_code, currency_name)
     */
    primaryCurrency: any;
    /**
     * Secondary currency object holds (iso2, currency_code, currency_name)
     */
    secondaryCurrency: any;
    /**
     * Primary currency change handler
     */
    primaryCurrencyChangeHandler: Function;
    /**
     * Secondary currency change handler
     */
    secondaryCurrencyChangeHandler: Function;
    /**
     * Sending currency value (each dollar rate)
     */
    sendingCurrencyValue: string;
    /**
     * Receiving currency value (each dollar rate)
     */
    receivingCurrencyValue: string;
    /**
     * Sending currency amount (user input)
     */
    sendingCurrencyAmount?: string | number;
    /**
     * Receiving currency amount (user input)
     */
    receivingCurrencyAmount?: string | number;
    /**
     * Swap currencies handler
     */
    onSwapHandler: () => void;
    /**
     * Special rate in string for primary currency
     */
    primarySpecialRateText?: string;
    /**
     * Special rate in string for secondary currency
     */
    secondarySpecialRateText?: string;
    /**
     * `boolean` - to display loading state
     */
    sendAmountLoading?: boolean;
    /**
     * `boolean` - to display loading state
     */
    receiveAmountLoading?: boolean;
    /**
     * `function` - to handle send currency change. returns input string
     */
    onSendCurrencyAmountChange: Function;
    /**
     * `function` - to handle receive currency change. returns input string
     */
    onReceiveCurrencyAmountChange: Function;
    /**
     * `string` - to indicate user input value
     */
    sendAmount: string;
    /**
     * `string` - to indicate user input value
     */
    receiveAmount: string;
    sendFieldErrorMessage?: string;
    receiveFieldErrorMessage?: string;
    autoFocus?: boolean;
    currencyRateLoading?: boolean;
}

const HorizontalExchangeConverter: React.FC<CEBox> = ({
    id,
    countriesList,
    primaryCurrency,
    secondaryCurrency,
    primaryCurrencyChangeHandler,
    secondaryCurrencyChangeHandler,
    sendingCurrencyValue,
    receivingCurrencyValue,
    onSwapHandler,
    primarySpecialRateText,
    secondarySpecialRateText,
    sendAmountLoading,
    receiveAmountLoading,
    onSendCurrencyAmountChange,
    onReceiveCurrencyAmountChange,
    sendAmount,
    receiveAmount,
    autoFocus = true,
    sendFieldErrorMessage,
    receiveFieldErrorMessage,
    currencyRateLoading
}) => {
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`)
    return (
        <Box id={id} className={classes['rb-exchange-container']}>
            <Box className={classes['rb-converter-container-frame']}>
                <Box className={classes['rb-converter-frame']}>
                    <CurrencyInputField
                        label="You have"
                        id="sendingEnd"
                        countrySelectable={true}
                        flagCode={primaryCurrency?.iso2}
                        currency={primaryCurrency?.currency_code}
                        loading={sendAmountLoading}
                        editable={!receiveAmountLoading}
                        handleCountryChange={primaryCurrencyChangeHandler}
                        handleChange={onSendCurrencyAmountChange}
                        countriesList={countriesList}
                        value={sendAmount}
                        autoFocus={autoFocus}
                        errorMessage={sendFieldErrorMessage}
                    />
                    <div className={classes[`rb-circle`]}>
                        <Button onClick={onSwapHandler} id="swap-currency" variant='outlined' style={{ borderRadius: '50%', padding: '8px', height: '36px' }}><ExchangeIconHorizontal width='18px' height='18px' color={theme.palette.primary.blue} /></Button>
                    </div>
                    <CurrencyInputField
                        label="You receive"
                        id="receiveEnd"
                        countrySelectable={true}
                        flagCode={secondaryCurrency?.iso2}
                        currency={secondaryCurrency?.currency_code}
                        loading={receiveAmountLoading}
                        editable={!sendAmountLoading}
                        handleCountryChange={secondaryCurrencyChangeHandler}
                        handleChange={onReceiveCurrencyAmountChange}
                        countriesList={countriesList}
                        value={receiveAmount}
                        errorMessage={receiveFieldErrorMessage}
                    />
                </Box>
                <Button className={classes['rb-button']} id='exchange' variant='filledPrimary'>
                    Exchange now
                </Button>
            </Box>
            <Box display='flex' flexDirection='column' gap={4}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <div className={classes['rb-rate-info']}>
                        {currencyRateLoading ? <Skeleton width={120} height={40} /> : primaryCurrency && receivingCurrencyValue && <Typography id='amount' weight='semibold' variant={isMobile ? "body1" : 'h5'} color={theme.palette.primary.navy}>{` 1 ${primaryCurrency?.currency_code} =`} <span style={{ textDecoration: primarySpecialRateText ? 'line-through' : 'inherit' }}>{` ${receivingCurrencyValue} ${!primarySpecialRateText ? secondaryCurrency?.currency_code : ''}`}</span></Typography>}
                        {primarySpecialRateText && <Typography id="specialRate" weight='semibold' variant={isMobile ? "body1" : 'h5'} color={theme.palette.success.dark}>{` ${primarySpecialRateText} ${secondaryCurrency?.currency_code}`}</Typography>}
                        <div className={classes['rb-green-background']}><div></div></div>
                    </div>
                    <div className={classes['rb-fee-info']}>
                        <Typography variant='textMd'>Oru exchange fee:</Typography>
                        <Typography variant='textMd' color={theme.palette.success.dark}>$0.00 CAD</Typography>
                    </div>
                </Box>
                <div>

                    {currencyRateLoading ? <Skeleton width={120} height={40} /> : secondaryCurrency && sendingCurrencyValue && <Typography id='amount' weight='regular' variant={isMobile ? "label2" : 'label1'} color={theme.palette.input.body}>{` 1 ${secondaryCurrency?.currency_code} =`}<span style={{ textDecoration: secondarySpecialRateText ? 'line-through' : 'inherit' }}>{` ${sendingCurrencyValue} ${!secondarySpecialRateText ? primaryCurrency?.currency_code : ''}`}</span></Typography>}
                    {secondarySpecialRateText && <Typography id="specialRate" weight='regular' variant={isMobile ? "label2" : 'label1'} color={theme.palette.success.dark}>{` ${secondarySpecialRateText} ${primaryCurrency?.currency_code}`}</Typography>}
                </div>
            </Box>
        </Box>
    )
}

export default HorizontalExchangeConverter