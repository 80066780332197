import React, { useState, useRef, useEffect } from 'react';
import classes from './CarouselSection.module.scss';
import Box from '../../components/box/Box';
import Typography from '../../components/typography/Typography';
import Markdown from 'markdown-to-jsx';
import theme from '../../theme/Theme';
import Image from 'next/image';
import { parseArrFromString } from '../../../shared/utility';
import ArrowLeftLined from '../../icons/ArrowLeftLined';
import ArrowRightLined from '../../icons/ArrowRightLined';
import Spacing from '../../styles/spacing/Spacing';
import Container from '../../components/container/Container';

type CarouselItem = {
  id: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  cardAlign?: string;
  backgroundColor?: string;
};

type CarouselSectionProps = {
  itemsList?: CarouselItem[];
  title?: string;
  subtitle?: string;
  backgroundColor?: 'standard' | 'gray' | 'blue';
  scrollType?: 'horizontal' | 'arrows';
};

const CarouselSection: React.FC<CarouselSectionProps> = ({
  itemsList,
  title,
  subtitle,
  backgroundColor = 'blue',
  scrollType = 'arrows',
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const [visibleCards, setVisibleCards] = useState(3);
  const carouselRef = useRef<HTMLDivElement>(null);
  const items = typeof itemsList === 'string' ? parseArrFromString(itemsList) : itemsList;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prev) => Math.min(prev + visibleCards, (items?.length || 0) - visibleCards));
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.offsetWidth / visibleCards,
        behavior: 'smooth',
      });
    }
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => Math.max(prev - visibleCards, 0));
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -(carouselRef.current.offsetWidth / visibleCards),
        behavior: 'smooth',
      });
    }
  };

  if (!isMounted) {
    return null; // Prevent mismatched SSR and CSR renders
  }

  return (
    <section className={classes[backgroundColor]}>
      <Container className={classes['rb-carousel-section']}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={'16px'}
        >
          {title && typeof title === 'string' && (
            <Typography
              variant="displayXl"
              weight="bold"
              align="center"
              color={
                backgroundColor === 'blue'
                  ? theme.palette.background.white
                  : theme.palette.primary.navy
              }
            >
              <Markdown>{title}</Markdown>
            </Typography>
          )}
          {subtitle && typeof subtitle === 'string' && (
            <Typography
              variant="textLg"
              align="center"
              color={
                backgroundColor === 'blue'
                  ? theme.palette.background.white
                  : theme.palette.input.body
              }
            >
              <Markdown>{subtitle}</Markdown>
            </Typography>
          )}
        </Box>

        <Box>
          <div className={classes['rb-carousel-container']} ref={carouselRef}>
            {items?.map((item, index) => (
              <div
                key={item.id}
                className={classes['rb-card']}
                style={{
                  backgroundColor: item.backgroundColor
                    ? item.backgroundColor
                    : theme.palette.background.white,
                }}
              >
                <Box display="flex" alignItems={item.cardAlign} flexDirection="column">
                  {item.imageUrl && (
                    <Image src={item.imageUrl} alt="illustration" width={40} height={40} />
                  )}
                  <Spacing variant="betweenHeaderToSubheader" />
                  {item.title && (
                    <Typography variant="textXl" weight="bold" color={theme.palette.primary.navy}>
                      {item.title}
                    </Typography>
                  )}
                  {item.description && (
                    <Typography
                      variant="textMd"
                      color={theme.palette.input.body}
                      align={item.cardAlign}
                    >
                      {item.description}
                    </Typography>
                  )}
                </Box>
              </div>
            ))}
          </div>
        </Box>

        <div className={classes['rb-dots']}>
          {scrollType === 'arrows' && (
            <div className={classes['rb-controls']}>
              <button
                onClick={prevSlide}
                className={classes['rb-arrow']}
                style={{
                  backgroundColor:
                    (backgroundColor === 'blue' && theme.palette.primary.blue) ||
                    (backgroundColor === 'gray' && theme.palette.background.gray),
                }}
              >
                <ArrowLeftLined
                  color={
                    backgroundColor === 'blue'
                      ? theme.palette.background.white
                      : theme.palette.primary.blue
                  }
                />
              </button>
            </div>
          )}
          {items?.map((_, index) => (
            <div
              key={index}
              className={`${classes['dot']} ${
                index === currentIndex
                  ? backgroundColor === 'blue'
                    ? classes['active-white']
                    : classes['active']
                  : ''
              }`}
              onClick={() => {
                setCurrentIndex(index);
                if (carouselRef.current) {
                  carouselRef.current.scrollBy({
                    left: (index - currentIndex) * carouselRef.current.offsetWidth,
                    behavior: 'smooth',
                  });
                }
              }}
            ></div>
          ))}
          {scrollType === 'arrows' && (
            <div className={classes['rb-controls']}>
              <button
                onClick={nextSlide}
                className={classes['rb-arrow']}
                style={{
                  backgroundColor:
                    (backgroundColor === 'blue' && theme.palette.primary.blue) ||
                    (backgroundColor === 'gray' && theme.palette.background.gray),
                }}
              >
                <ArrowRightLined
                  color={
                    backgroundColor === 'blue'
                      ? theme.palette.background.white
                      : theme.palette.primary.blue
                  }
                />
              </button>{' '}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default CarouselSection;
